import { twMerge } from "tailwind-merge"

export const BlockItem = ({ children, className, innerClassName, onClick }: { children: React.ReactNode; className?: string; innerClassName?: string; onClick?: () => void }) => {
  return (
    <div className={twMerge("w-[calc(100%-8px)] mx-auto mt-2 rounded-sm overflow-hidden relative md:mt-1", className)} onClick={onClick}>
      <div className={twMerge("mx-5 my-6 md:mx-6", innerClassName)}>
        {children}
      </div>
    </div>
  )
}